@import "bootstrap/dist/css/bootstrap.css";
@font-face {
    font-family: PPNeueBit-Bold;
    src: url("assets/Fonts/PPNeueBit-Bold.otf");
}
@font-face {
    font-family: PPNeueMontreal-Bold;
    src: url("assets/Fonts/PPNeueMontreal-Bold.otf");
}
@font-face {
    font-family: MyriadPro-Regular;
    src: url("assets/Fonts/MyriadPro-Regular.otf");
}

.front-body {
  height: 100%;
  background: linear-gradient( to top, #1bae4b 0%, #1bae4b 50%, #00acd7 0%, #00acd7 100% );
}

.red-snackbar{ /*For text*/
    color: white !important;
    background-color: red !important;
}
.red-snackbar span{ /*For action*/
    color: white !important;
}

body {
  background-color: #d7e0ad !important;
  position: absolute;
  margin: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
}
* {
    padding: 0;
    margin: 0;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

.streaming-icons {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.store-cart {
  margin-top: 5px;
  height: 35px;
}

.scale {
    margin-left: 20px;
    height: 50px;
}


.title {
  height:130px;
  margin-top: -40px;
}




.btn-shake:hover {
    transform: scale(1.2) perspective(1px)
}

.subscribe{
    float: right;
    margin-right: 40px;
    margin-top: 10%;
    height: 55px;

}




@media screen and (max-width: 1300px) {
  .socials-desktop, .scale, .streaming-icons, .btn-shop, .subscribe,
  .main-img, .title, .info-top, .info-sub, .column-info, .column-info-bot, .info-bot, .info-title, .front-body {
    display: none;
  }
}


@media screen and (min-width: 1300px) {
    .socials-mobile, .socials, .front-title, footer, .front-btn, .mobile-img, .logo-mobile, .btn-info-mobile, .store-cart-mobile,
    .row-info-mobile, .info-top-mobile, .info-sub-mobile, .column-info-mobile, .info-tour-mobile, .footer-info,
    .info-bot-mobile, .btn-back-merch, .back-to-merch
    {  display: none;
    }
}

/* MOBILE index *//*  MOBILE index */



.mobile-img {
    width: 100%;
    margin-top: 20px;
    max-width: 270px;
}

.row-mobile{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 15px;
}

.row-socials{
    display: flex;
    border-top: 4px solid black;
    justify-content: center;
    background-color: white;

}
.col-socials{
    margin: 10px 15px;
    display: flex;
}
.socials{
    bottom: 0px;
    position: fixed;
    width: 100%;
    background-color: #d7e0ad;
}
.socials-mobile {
    height: 30px;
}


/* MOBILE tour *//*  MOBILE tour */





.footer-tour{
    margin-bottom: 70px;
}
/* MOBILE info *//*  MOBILE info */


.row-info-mobile{
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
}
.info-top-mobile{
    display: flex;
    font-family: PPNeueBit-Bold;
    font-size: 30px;
    color: black;
    justify-content: space-between;

}
.info-sub-mobile{
    display: flex;
    font-family: PPNeueMontreal-Bold;
    font-size: 30px;
    color: black;
    justify-content: space-between;
    border-bottom: 2px solid black;
}
.column-info-mobile{
    font-family: PPNeueBit-Bold;
    font-size: 45px;
    padding: 10px 10px 0px 10px;
    margin-bottom: 40px;
}

.info-tour-mobile{
    font-family: PPNeueBit-Bold;
    font-size: 45px;
    padding: 10px 10px 0px 10px;
    margin-left: 20px;
}

.info-bot-mobile {
    text-decoration: none;
    color: black;
    font-family: PPNeueBit-Bold;
    font-size: 30px;
    padding: 0px 0px 0px 7px;
    margin-left: 20px;

}

.footer-info{
    margin-bottom: 70px;
}


/* INFO PAGE */

.info-top {
  font-family: PPNeueBit-Bold;
  font-size: 50px;
  color: black;
}
.info-sub {
  color: black;
  font-family: PPNeueMontreal-Bold;
  font-size: 50px;
  border-bottom: 2px solid black;
  width: 60vw;
}
.row-info{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.column-info {
  color: black;
  flex: 50%;
  flex-direction: column;
  border-width: 10px 1px;
}

.column-info-bot  {
    margin-top: 10px;
    margin-bottom: -7px;
    flex-direction:column;
    align-self: flex-end;
}
.info-bot {
    text-decoration: none;
    color: black;
    font-family: PPNeueBit-Bold;
    font-size: 40px;
}

.info-bot:hover{
  color: black;
}

.info-title {
  color: black !important;
  font-family: PPNeueBit-Bold;
  font-size: 100px;
}


/* TOUR PAGE */

.tour-title{
    font-family: PPNeueBit-Bold;
    font-size: 100px;
}




.size-q{
    font-family: PPNeueBit-Bold;
    font-size: 20px;
    margin: 10px;
    line-height: 10px;
    color: black;

}
.custom-select {
    position: relative;
    font-family: PPNeueBit-Bold;
    font-size: 20px;
    margin-top: 5px;
    margin-left: 10px;
}

.custom-select select {
    background-color: #fc7600;
}

.select-selected {
    background-color: #fc7600;
}
.select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: black transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
    border-color: transparent transparent black transparent;
    top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,.select-selected {
    color: black;
    padding: 4px 8px;
    border: 1px solid black;
    border-color: transparent transparent black transparent;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    background-color: #fc7600;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

.select-items div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}


.btn-add-to-cart {
    background-color: #d7e0ad;
    font-family: PPNeueBit-Bold;
    font-size: 30px;
    margin: 50px 10px;
    border: 2px solid black;
    padding: 0px 40px;
    border-radius: 15px;
}

.btn-back-merch {
    background-color: #d7e0ad;
    font-family: PPNeueBit-Bold;
    font-size: 30px;
    margin: 0px 10px;
    border: 2px solid black;
    padding: 0px 40px;
    border-radius: 15px;

}
.back-merch {
    text-decoration: none;
    color: black;
}


.card{
    margin: auto;
    max-width: 100%;
    width: 90%;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 1rem;
    border: transparent;
    margin: 3vh auto;
}

.cart{
    background-color:  #d7e0ad;
    padding: 4vh 5vh;
}

.summary{

    background-color: #777777;
    padding: 4vh;
}

.summary .col-2{
    padding: 0;
}
.summary .col-10
{
    padding: 0;
}.row{
     margin: 0;
 }
.title b{
    font-size: 2rem;
    font-family: PPNeueBit-Bold;
    color: black;
}
.main{
    margin: 0;
    padding: 2vh 0;
    width: 100%;
    font-family: PPNeueBit-Bold;
    font-size: 1.5rem;
}
.col-2, .col{
    padding: 0 1vh;
    font-size: 1.5rem;
    font-family: PPNeueBit-Bold;
    color: black;

}

.close{
    margin-left: auto;
    font-size: 0.7rem;
}
{
    width: 3.5rem;
}
.back-to-merch{
    margin-top: 4.5rem;
    font-size: 30px;
    text-decoration: none;
    color: black;
    font-family: PPNeueBit-Bold;
    padding: 0 1vh;
    background-color: #d7e0ad;
    border: 2px solid black;
}
h5{
    margin-top: 4vh;
    color: black;
    font-family: PPNeueBit-Bold;
}

form{
    padding: 2vh 0;
    font-family: PPNeueBit-Bold;
    color: black;
    font-size: 20px;
}
select{
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 1.5vh 1vh;
    margin-bottom: 4vh;
    outline: none;
    width: 100%;
    background-color: #e6e6e6;
}
input{
    border: 1px solid rgba(0, 0, 0, 0.137);
    padding: 1vh;
    margin-bottom: 4vh;
    outline: none;
    width: 100%;
    background-color: #e6e6e6;
}

.btn-checkout{
    border-color: black;
    color: black;
    width: 100%;
    margin-top: 4vh;
    padding: 1vh;
    font-family: PPNeueBit-Bold;
    background-color: #d7e0ad;
    font-size: 30px;

}

/* sign in form *//* sign in form */
.form-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.row-form{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 15px;
}

.login-btn{
    background-color: #d7e0ad;
    font-family: PPNeueBit-Bold;
    font-size: 35px;
    border: 2px solid black;
    padding: 0px 120px;
    border-radius: 15px;
    margin-top: 10px;
}
.register-btn{
    background-color: #d7e0ad;
    font-family: PPNeueBit-Bold;
    font-size: 35px;
    border: 2px solid black;
    padding: 0px 120px;
    border-radius: 15px;
    margin-top: 20px;
}

label{
    font-family: PPNeueBit-Bold;
    font-size: 25px;
}
.form-title{
    font-family: PPNeueBit-Bold;
    font-size: 40px;
    color: black;
    margin-top: 20px;
}

.form{
    font-family: PPNeueBit-Bold;
    font-size: 20px;
    color: black;
    text-decoration: none;
}
.form-tp{
    font-family: PPNeueBit-Bold;
    font-size: 20px;
    text-decoration: none;
    margin-bottom: -50px;
}
.form-links{
    text-decoration: none;
    color: #fc7600;
}
.form-links:hover{
    text-decoration: none;
    color: black;
}
.login-mobile{
    width: 44px;
    float: right;
    margin-top: 7px;
    margin-left: 7px;
}

/* fallback */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}
/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.filter-btn{
  height: auto;
  width: auto;
  padding:  0 10px;
  background-color: rgba(0,0,0,0);
  border: 1px dashed;
  border-radius: 40px;
}
.filter-btn .light{
  border-color:white;
  color: white;
}


/**MATGERIAL**/
.mat-mdc-form-field {
  display: block !important;
}
